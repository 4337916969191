<template>
  <div class="form-wrapper">
    <div class="components-config">
      <ComponentName name="控件" />
      <div class="components-list" style="overflow-y: auto;max-height: 90%;">
        <div class="components components-box" v-for="(group, i) in baseComponents" :key="i">
          <p class="components-group">{{ group.name }}</p>
          <ul>
            <draggable
              item-key="id"
              class="drag"
              :list="group.components"
              :sort="false"
              :animation="100"
              :group="{ name: 'form', pull: 'clone', put: false }"
              :clone="clone"
              @end="changEnd"
            >
              <template #item="{ element }">
                <li v-if="!element.hideTypeList || !element.hideTypeList.includes(basic.processType)">
                  <span>{{ element.title }}</span>
                  <svg class="icon svg-icon" aria-hidden="true">
                    <use :xlink:href="'#' + element.icon"></use>
                  </svg>
                </li>
              </template>
            </draggable>
          </ul>
        </div>
      </div>
    </div>
    <div class="design-form-box">
      <h1 class="flow-name">{{ basic.name || '自定义流程名称' }}</h1>
      <div class="defalut"></div>
      <draggable
        class="design-form"
        :list="formsData"
        item-key="id"
        :group="{ name: 'form', pull: '' }"
        chosen-class="choose"
        :animation="300"
        :sort="true"
      >
        <template #item="{ element, index }">
          <div
            :class="['form-item', { 'current-form': selectFormItem?.id === element.id }]"
            @click="selectitem(element)"
            :style="{ border: selectFormItem?.id === element.id ? '1px dashed #0A7BFF' : '1px dashed rgba(0,0,0,0)' }"
          >
            <FormDesignRender :config="element" />
            <span
              class="option-del"
              v-if="selectFormItem?.id === element.id && !element.props.disabledDel"
              @click.stop="clickDel(element, index)"
            >
              <i class="iconfont icon-delete"></i>
            </span>
          </div>
        </template>
      </draggable>
    </div>
    <div class="work-form-box">
      <div class="empty-config" v-if="!selectFormItem || formsData?.length === 0">
        <img src="@/assets/images/form-config-empty.png" alt="" srcset="" />
        <h2>选中控件后在这里进行编辑</h2>
      </div>
      <div v-else>
        <ComponentName :name="selectFormItem.controlType" />
        <formConfigRender />
      </div>
    </div>
  </div>
</template>

<script setup>
import FormDesignRender from './commonViews/formDesignRender.vue'
import ComponentName from './commonViews/componentName.vue'
import formConfigRender from './commonViews/formConfigRender.vue'
import { computed, nextTick, reactive, ref, onMounted } from 'vue'
import { baseComponents } from './componentsConfig.js'
import draggable from 'vuedraggable'
import { useStore } from 'vuex'
import { loadInitFrom } from '@/utils/hooks'
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
const store = useStore()
const formsData = computed(() => store.state.process.design.formItems)
const basic = computed(() => store.state.process.basic)
// 选中的控件信息
const selectFormItem = computed({
  get() {
    return store.state.process.selectFormItem
  },
  set(val) {
    console.log(val)
    store.state.process.selectFormItem = val
  }
})

onMounted(() => {
  // console.log(formsData.value);
  // formsData.value.forEach(item => {
  //   if(item.configName === 'SealInfo' && !item.props.items[2].props.stamp) {
  //     item.props.items[2].props = {
  //       requirred: true,
  //       stamp: []
  //     }
  //   }
  // })
})

const del = () => {}
const drag = ref(false)
const isStart = ref(false)
const getId = () => {
  return (
    'field' +
    (Math.floor(Math.random() * (99999 - 10000)) + 10000).toString() +
    new Date()
      .getTime()
      .toString()
      .substring(5)
  )
}
let cloneId = ''
const clone = obj => {
  obj.id = getId()
  cloneId = obj.id
  return JSON.parse(JSON.stringify(obj))
}

const changEnd = e => {
  selectFormItem.value = null
  let obj = formsData.value.find(item => item.id === cloneId)
  console.log(obj)
  selectFormItem.value = obj
  cloneId = ''
}

// 选中控件
const selectitem = element => {
  selectFormItem.value = null
  if (element.type === 'REMOTE') {
    const options = ['常规盖印', '连续盖印', '远程盖印']
    element.props.options = options.map((item, index) => {
      const optionCopy = element.props.options[index]
      return {
        name: optionCopy?.name ? optionCopy?.name : optionCopy ? optionCopy : item,
        isOpen: optionCopy?.name ? optionCopy.isOpen : optionCopy !== null ? true : false,
        childSelect: optionCopy?.childSelect ? optionCopy.childSelect : [1, 2] //范围用印&录制视频
      }
    })
  }
  selectFormItem.value = element
}

loadInitFrom()

let conditionsList = []
let currentFieldId = 0
let cidsList = []

function checkNode(node) {
  if (!['CONDITIONS', 'CONDITION'].includes(node.type)) {
    // 不是条件分支，进入下一次递归
    if (node.children?.id) checkNode(node.children)
    return
  }

  if (node.type == 'CONDITIONS') {
    // 条件分支父级虚拟节点
    node.branchs.forEach(it => checkNode(it))
  } else if (!node.typeElse) {
    // 有条件的分支节点，且不是默认节点，获取条件信息
    // node.typeElse true 为默认节点
    let hasId = node.props.groups[0].conditions.some(it => it?.id == currentFieldId)
    hasId && conditionsList.push(node.props.groups[0].conditions)
    hasId && cidsList.push(node.props.groups[0].cids)
  }
  if (node.children?.id) checkNode(node.children)
}

// 删除组件
function clickDel(element, index) {
  // 如果不是金额或者数值，直接删除
  if (!['NumberInput', 'AmountInput'].includes(element.configName)) {
    formsData.value.splice(index, 1)
    selectFormItem.value = null
  } else {
    const nodes = store.state.process.design.process

    currentFieldId = element.id
    checkNode(nodes.children)
    currentFieldId = 0

    console.log('%c conditionsList', 'color:red;font-size:16px', conditionsList)

    if (!conditionsList.length) {
      formsData.value.splice(index, 1)
      selectFormItem.value = null
      return
    }
    Modal.confirm({
      title: '是否删除！',
      width: '420px',
      content: '该组件已被设为分支条件，删除后，流程设置中的相关的条件及设置将同步删除',
      centered: true,
      icon: createVNode(ExclamationOutlined),
      onOk: () => {
        conditionsList.forEach((el, ind) => {
          console.log(1111, el)
          const delIndex = el.findIndex(e => e.id === element.id)
          if (delIndex !== -1) {
            el.splice(delIndex, 1)
            cidsList[ind].splice(delIndex, 1)
          }
        })
        formsData.value.splice(index, 1)
        selectFormItem.value = null
        console.log(cidsList)

        conditionsList = []
        cidsList = []
      },
      onCancel: () => {
        conditionsList = []
        cidsList = []
      }
    })
  }
}
</script>

<style lang="less" scoped>
.components-config {
  margin-top: 60px;
  width: 356px;
  background: #fff;
}
.components-box {
  margin-bottom: 22px;
}
.components {
  font-size: 12px;
  width: 100%;
  padding: 0 24px;
  &-list {
    padding: 40px 0;
  }
  &-group {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    padding-bottom: 12px;
  }
  .drag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    li {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 8px;
      margin-bottom: 10px;
      border: 1px solid #000;
      border-radius: 8px;
      cursor: grab;
      background-color: #fff;
      width: 146px;
      height: 40px;
      background: #f9f9f9;
      color: #333333;
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      &:hover {
        border: 1px solid #0A7BFF;
        color: #0A7BFF;
        .svg-icon {
          fill: #0A7BFF;
        }
      }
    }
  }
}
.required-icon{
  color:#c3161c !important;
}
.svg-icon {
  width: 1em;
  font-size: 14px;
  fill: currentColor;
}
.form-item {
  cursor: grab;
  background: #ffffff;
  list-style: none;
  padding: 10px 24px 10px;
  margin-bottom: -1px;
}
.form-wrapper {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: space-between;
}
.design-form-box {
  margin-top: 86px;
  width: 520px;
  background: #f8f8f8;
  border: 1px solid #f0f0f0;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 0 16px;
  .defalut {
    height: 24px;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .design-form {
    height: calc(100% - 100px);
    padding: 0 0px 16px;
    background: #fff;
    // height: 100%;
    overflow: scroll;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      height: 60px;
      cursor: grab;
    }
  }
}
.current-form {
  border-radius: 6px;
  background: #f8f8f8;
  position: relative;
}
.flow-name {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 16px 0 16px;
  position: relative;
}
li {
  list-style: none;
}
.work-form-box {
  margin-top: 60px;
  width: 356px;
  background: #fff;
  overflow: scroll;
}
.empty-config {
  margin-top: 200px;
  text-align: center;
  h2 {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
}
.option-del {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  top: -20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: auto;
  z-index: 199;
  .iconfont {
    color: #333333;
  }
}
</style>
