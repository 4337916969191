export const ValueType = {
  string: 'String',
  object: 'Object',
  array: 'Array',
  number: 'Number',
  date: 'Date',
  user: 'User',
  dept: 'Dept',
  dateRange: 'DateRange'
}
export const baseComponents = [
  {
    name: '文本',
    components: [
      {
        title: '单行文本',
        controlName: 'TextInput',
        controlType: '单行文本',
        configName: 'TextInput',
        value: '',
        icon: 'icon-text-input',
        valueType: ValueType.string,
        props: {
          required: false
        }
      },
      {
        title: '多行文本',
        controlName: 'TextareaInput',
        configName: 'TextareaInput',
        controlType: '多行文本',
        value: '',
        icon: 'icon-text-area-input',
        valueType: ValueType.string,
        props: {
          required: false
        }
      }
    ]
  },
  {
    name: '数值',
    components: [
      {
        title: '数字',
        controlName: 'NumberInput',
        controlType: '数字',
        configName: 'NumberInput',
        value: '',
        icon: 'icon-number-input',
        valueType: ValueType.number,
        props: {
          required: false,
          unit: '', //单位
          showChinese: true,
          precision: true // 保留小数点
        }
      },
      {
        title: '金额',
        controlName: 'AmountInput',
        controlType: '金额',
        configName: 'AmountInput',
        value: '',
        icon: 'icon-amount-input',
        valueType: ValueType.number,
        props: {
          required: false,
          currencyType: '人民币', //币种
          showChinese: true, // 展示中文大写
          precision: true // 保留小数点
        }
      }
    ]
  },
  {
    name: '选项',
    components: [
      {
        title: '单选',
        controlName: 'SelectInput',
        controlType: '单选',
        configName: 'SelectInput',
        value: '',
        icon: 'icon-select-input',
        valueType: ValueType.string,
        props: {
          required: false,
          options: ['选项1']
        }
      },
      {
        title: '多选',
        controlName: 'MultipleSelect',
        controlType: '多选',
        configName: 'MultipleSelect',
        value: [],
        icon: 'icon-multiple-select',
        valueType: ValueType.array,
        props: {
          required: false,
          options: ['选项1']
        }
      }
    ]
  },
  {
    name: '日期',
    components: [
      {
        title: '日期',
        controlName: 'DateTime',
        controlType: '日期',
        configName: 'DateTime',
        value: '',
        icon: 'icon-date-time',
        valueType: ValueType.date,
        props: {
          required: false,
          format: 'yyyy-MM-DD'
        }
      },
      {
        title: '日期区间',
        controlName: 'DateTimeRange',
        controlType: '日期区间',
        configName: 'DateTimeRange',
        valueType: ValueType.array,
        icon: 'icon-date-time-range',
        props: {
          required: false,
          showDuration: true,
          durationTitle: '时长', //时长控件名字
          placeholder: ['开始时间', '结束时间'],
          format: 'yyyy-MM-DD',
          showLength: false
        }
      }
    ]
  },
  {
    name: '其他',
    components: [
      {
        title: '附件',
        controlName: 'FileUpload',
        controlType: '附件',
        configName: 'FileUpload',
        icon: 'icon-file-upload',
        value: [],
        type: 'FILE_ELSE',
        valueType: ValueType.array,
        props: {
          required: false,
          isAnnex: true
        }
      },
      {
        title: '地址',
        controlName: 'Address',
        controlType: '地址',
        configName: 'Address',
        icon: 'icon-address',
        value: [],
        valueType: ValueType.array,
        props: {
          required: false
        }
      },
      {
        title: '电话',
        controlName: 'Phone',
        controlType: '电话',
        configName: 'Phone',
        icon: 'icon-dianhua',
        value: '',
        valueType: ValueType.string,
        props: {
          required: false
        }
        // hideTypeList: [1]
      },
      {
        title: '事项编号',
        controlName: 'TextInput',
        controlType: '单行文本',
        configName: 'TextInput',
        icon: 'icon-number-input',
        value: '',
        valueType: ValueType.string,
        type: 'TASK_CODE',
        props: {
          required: false
        }
        // hideTypeList: [1]
      }
    ]
  }
]
export default {
  baseComponents
}
